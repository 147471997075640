import { UserInfo } from '@/vo/UserInfo'
import { APP_ID, LocalStorageKey, SessionStorageKey } from '@/config/enum'
import { VuexMutationKey } from '@/store/vuexEnum'
import http from '../http'
import router from '@/router'
import store from '@/store'
import { getUserInfo } from '../special/getUserInfo'
import { apiOpenTeacherLogin } from '@/api/login'

export const isLogin = function (): boolean {
	return getUserInfo() ? true : false
}

export const setUserInfo = function (userInfo: UserInfo) {
	if (!userInfo) return
	const oldUserInfo = getUserInfo()
	if (oldUserInfo) Object.assign(oldUserInfo, userInfo)
	userInfo = oldUserInfo || userInfo
	localStorage.setItem(LocalStorageKey.USER_INFO, JSON.stringify(userInfo))
	sessionStorage.setItem(SessionStorageKey.USER_INFO, JSON.stringify(userInfo))
	store.commit(VuexMutationKey.updateUserInfo, userInfo)
}

export const loginOut = function () {
	console.log('loginout...')
	const isOperator = getUserInfo()?.__isOperator
	clearStorage()

	let info = router.resolve({
		name: !isOperator ? 'login' : 'operatorLogin',
	})
	let url = location.origin + info.fullPath

	if (!location.href.includes(url)) {
		location.href = url
	}
	// if (router) {
	// 	router.push({
	// 		name: 'login',
	// 	})
	// }
}

export function updateUserInfo() {
	return http<UserInfo>(
		'GET',
		'/api/student/userInfo',
		{},
		{
			tokenExpireUncheck: true,
			showMsg: false,
		}
	).then(userInfo => {
		setUserInfo(userInfo)
	})
}

interface ThirdAppParams {
	appId: string
	teacherId: string
	sign: string
	nonce: string
}

export function queryZZDThirdAppUserInfo(params: ThirdAppParams) {
	return new Promise(resolve => {
		const userInfo = {
			...params,
			schoolType: 0,
			schoolName: '',
			schoolId: 0,
			account: '',
			userId: params.teacherId,
			status: '',
			userType: 0,
			createTime: '',
			name: '',
			nickName: '',
			headPic: '',
			accessToken: '',
			phone: '',
			staffName: '',
			staffPhone: '',
		}
		setThirdUserInfo(userInfo)
		resolve(userInfo)
	})
}

export function queryJYDThirdAppUserInfo(params: Omit<ThirdAppParams, 'teacherId'>) {
	return new Promise(resolve => {
		apiOpenTeacherLogin(params).then(res => {
			res.appId = params.appId
			setThirdUserInfo(res)
			resolve(res)
		})
	})
}

export const setThirdUserInfo = function (userInfo: UserInfo) {
	if (!userInfo) return
	const oldUserInfo = getUserInfo()
	if (oldUserInfo) Object.assign(oldUserInfo, userInfo)
	userInfo = oldUserInfo || userInfo
	sessionStorage.setItem(SessionStorageKey.THIRD_USER_INFO, JSON.stringify(userInfo))
	store.commit(VuexMutationKey.updateThirdUserInfo, userInfo)
	store.commit(VuexMutationKey.updateUserInfo, userInfo)
}

export function clearThirdUserInfo() {
	sessionStorage.removeItem(SessionStorageKey.THIRD_USER_INFO)
	store.commit(VuexMutationKey.updateThirdUserInfo, null)
}

// 判断用户是否来自教育钉/浙政钉
export const isJYDOrZZD = function () {
	let userinfo: UserInfo | undefined = getUserInfo()
	if (!userinfo) {
		return false
	}
	if (userinfo.appId && (APP_ID.JYD === userinfo.appId || APP_ID.ZZD === userinfo.appId)) {
		return true
	}
	return false
}

export const clearStorage = function () {
	localStorage.clear()
	sessionStorage.clear()
	store.commit(VuexMutationKey.updateUserInfo, null)
	store.commit(VuexMutationKey.updateThirdUserInfo, null)
}
